<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-end mb-2"
      >
        <b-button
          variant="primary"
          :to="{ name: 'InquiriesActivityLogs' }"
        >
          <FeatherIcon icon="ActivityIcon" />
          {{ $t('activity_logs') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <b-card-header>
            <h3>{{ $t('what_is_your_concern') }}</h3>
          </b-card-header>
          <b-card-body>
            <VueSelect
              v-model="form.inquiry_type_id"
              :options="inquiryTypes"
              :clearable="false"
              label="title"
              :reduce="inquiry => inquiry.id"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="form.inquiry_type_id">
      <b-col md="12">
        <b-card>
          <b-card-header class="mb-2">
            <h3>{{ $t('recipient_address_entry_request') }}</h3>
          </b-card-header>

          <b-card-body>
            <ValidationObserver
              ref="inquiryForm"
              v-slot="{ passes }"
            >
              <form @submit.prevent="passes(handleSubmitInquiry)">
                <b-col cols="12">
                  <AddressEntryInquiry
                    v-if="form.inquiry_type_id === 1"
                    :form="form"
                    :is-loading="isLoading"
                    @submit-inquiry="handleSubmitInquiry"
                  />
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-button
                    variant="primary"
                    class="float-right my-2"
                    type="submit"
                    :disabled="isLoading"
                  >
                    <b-spinner
                      v-if="isLoading"
                      class="mr-50 p-0"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    {{ $t('submit_inquiry') }}
                  </b-button>
                </b-col>
              </form>
            </ValidationObserver>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueSelect from 'vue-select'
import { AddressEntryInquiry } from '@/components'
import { ValidationObserver } from 'vee-validate'
import axios from '@axios'

export default {
  name: 'MyInquiries',
  components: {
    VueSelect,
    AddressEntryInquiry,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      inquiryTypes: [],
      form: {
        form_data: {
          address: {},
          client: {},
        },
      },
    }
  },
  mounted() {
    this.fetchInquiryTypes()
  },
  methods: {
    async fetchInquiryTypes() {
      const response = await axios.get('/inquiry-type')
      if (response.status === 200) {
        this.inquiryTypes = response.data
      }
    },
    async handleSubmitInquiry() {
      try {
        this.isLoading = true
        const response = await axios.post('/inquiries', this.form)
        if (response.status === 422) {
          this.$refs.inquiryForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        if (response.status === 200) {
          this.$swal({
            icon: 'success',
            title: 'Inquiry Submitted',
            text: 'Your request submitted! Sit tight for approval!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.form = {
              form_data: {
                client: {},
                address: {},
              },
            }
          })
        }
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
